import { render, staticRenderFns } from "./AirportDomesticMovementTable.vue?vue&type=template&id=07db2a1f&scoped=true&"
import script from "./AirportDomesticMovementTable.vue?vue&type=script&lang=js&"
export * from "./AirportDomesticMovementTable.vue?vue&type=script&lang=js&"
import style0 from "./AirportDomesticMovementTable.vue?vue&type=style&index=0&id=07db2a1f&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07db2a1f",
  null
  
)

export default component.exports